import React, {useState} from "react";
import { Formik } from 'formik';
import Outstation from "../Outstation";
import Local from "../Local";
import Airport from "../Airport";
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';

const StartingForm = () => {
    const [value, setValue] = useState(null);
    const [selectedId, setSelectedId] = useState('1');
    if(value) {
        console.log(value.label);
        geocodeByAddress(value.label)
  .then(results => getLatLng(results[0]))
  .then(({ lat, lng }) =>
    console.log('Successfully got latitude and longitude', { lat, lng })
  );
    }

    const Options = [
{
    itemId: '1',
    name: 'Outstation'
},
{
    itemId: '2',
    name: 'Local'
},
{
    itemId: '3',
    name: 'Airport'
}
    ];

const onselect = (id) => {
setSelectedId(id);
}
    return (
        <div>
            <section id="book-a-table" className="bg-form">
            <div className="pt-5">
                <div className="navbar-brand" style={{ fontSize: 42 }}><span style={{ fontSize: 42 }}>Book </span>a cab</div>
            </div>
            <div class="row m-0 justify-content-center pb-5">
            <div class="form_wrap">
            <div class="row m-0 mb-3 headerwrap">
            {Options.map((item)=>(<>
                    {item.itemId === selectedId ? (<div className="col-4 p-3 text-center activemenu" onClick={() => {onselect(item.itemId)}}>{item.name}</div>) : (<div className="col-4 p-3 text-center cursor_pointer" onClick={() => {onselect(item.itemId)}}>{item.name}</div>)}
                    </>
            ))
            }
            </div>
            {selectedId === '1' && (
                <Outstation />
            )}
            {selectedId === '2' && (
                <Local />
            )}
            {selectedId === '3' && (
                <Airport />
            )}
            </div>
            </div>
    </section>
        </div>
    )
}
export default StartingForm;