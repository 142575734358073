import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import DatePicker from "react-datepicker";
import moment from "moment";
import Select from 'react-select';
import axios from "axios";
import variable from '../../common/variable';

const PersonalInformation = () => {
    const TripData = JSON.parse(window.localStorage.getItem('tripDetails'));
    const [selectedId, setSelectedId] = useState('1');
    const [formShow, setformShow] = useState('');

    const pickUpArray = TripData.pickUp.split(",");
    const dropArray = TripData.drop.split(",");
    const ConfigURl = variable.host;
    const ssl = window.location.hostname === 'localhost' ? '' : 's';

    const onselect = (id) => {
        setSelectedId(id);
    }

    const total = () => { return Number(percentage(Number(window.localStorage.getItem('vehiclePrice')), Number(5))) + Number(window.localStorage.getItem('vehiclePrice')) }

    const saveOutstaion = (data) => {
        window.localStorage.setItem('Pnr', uniqueId());
        window.localStorage.setItem('finalAmt', `${total().toFixed(2)}`)
        window.localStorage.setItem('personDetails', JSON.stringify(data));
        const dataToSend = {};
        dataToSend.name = data.name;
        dataToSend.email = data.email;
        dataToSend.phoneNumber = data.phoneNumber;
        dataToSend.address = data.address;
        dataToSend.pnr = window.localStorage.getItem('Pnr');
        dataToSend.from = pickUpArray[0];
        dataToSend.to = dropArray[0];
        dataToSend.checkInDate = TripData.checkInDate
        dataToSend.checkOutDate = TripData.checkOutDate
        dataToSend.time = TripData.time
        dataToSend.vehicleName = window.localStorage.getItem('vehicleName')
        dataToSend.finalAmt = window.localStorage.getItem('finalAmt')
        dataToSend.paymentMode = data.pay
        console.log(dataToSend);

        const dataToSendCustomer = {};
        dataToSendCustomer.name = data.name;
        dataToSendCustomer.email = data.email;
        dataToSendCustomer.phoneNumber = data.phoneNumber;
        dataToSendCustomer.pnr = window.localStorage.getItem('Pnr');
        dataToSendCustomer.from = pickUpArray[0];
        dataToSendCustomer.to = dropArray[0];
        dataToSendCustomer.checkInDate = TripData.checkInDate
        dataToSendCustomer.checkOutDate = TripData.checkOutDate
        dataToSendCustomer.time = TripData.time
        dataToSendCustomer.vehicleName = window.localStorage.getItem('vehicleName')
        dataToSendCustomer.finalAmt = window.localStorage.getItem('finalAmt')
dataToSendCustomer.tripType = TripData.picked;
dataToSendCustomer.vehicleId = window.localStorage.getItem('vehicle_Id');
dataToSendCustomer.paymentMode = data.pay
console.log('dataToSendCustomer', dataToSendCustomer);
        axios.post(`${ConfigURl}/addCustomer`, dataToSendCustomer)
            .then(function (response) {
                
            })
            .catch(function (error) {
                console.log(error);
            });
            axios.post(`${ConfigURl}/email`, dataToSend)
                    .then(function (response) {
                        console.log(response.data);
                        window.location.replace('BillingDetails');
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
    }
    const change = (e) => {
        console.log('eee', e.target.value);
        setformShow(e.target.value);
    }
    const percentage = (num, per) => {
        return ((num / 100) * per).toFixed(2);
    }
    const submitPersonalInfo = () => {

    }

    var letters = "ABCDEFGHJKMNPQRSTUXY";
    var uniqueId = function () {
        var text = "";
        for (var i = 0; i < 5; i++) {
            text += letters.charAt(Math.floor(Math.random() * letters.length));
        }
        return text;
    };

    const tripInfo = (tripInfo) => {
        console.log('Number(tripInfo) ===', tripInfo);
        let place;
        if (Number(tripInfo) === 8) {
            place = 'Local (8Hrs)'
        } else if (Number(tripInfo) === 12) {
            place = 'Local (12Hrs)'
        } else {
            place = tripInfo;
        }
        return place;
    }


    return (
        <div>
            <div>
                <section id="book-a-table" className="bg-form-personal">
                    <div className="pt-5">
                        <div className="navbar-brand" style={{ fontSize: 42 }}><span style={{ fontSize: 42 }}>Book </span>a cab</div>
                    </div>
                    <div class="row m-0 justify-content-center">
                        <div className='col-12 col-md-6 d-flex justify-content-center'>
                            <div class="form_wrap_personal">
                                <Formik
                                    initialValues={{
                                        pickUp: '',
                                        drop: '',
                                    }}
                                    onSubmit={async (values) => {
                                        await saveOutstaion(values);
                                    }}
                                >
                                    <Form className="px-3">
                                        <div class="form-group text-left mt-5">
                                            <label class="label form_label">Name</label>
                                            <Field type="text" name="name" class="form-control pac-target-input" placeholder="Name" required autocomplete="off" />
                                        </div>
                                        <div class="form-group text-left">
                                            <label class="label form_label">Email Id</label>
                                            <Field type="email" name="email" class="form-control pac-target-input" placeholder="Email Id" required autocomplete="off" />
                                        </div>
                                        <div class="form-group text-left">
                                            <label class="label form_label">Phone Number</label>
                                            <Field type="number" name="phoneNumber" class="form-control pac-target-input" placeholder="Phone Number" required autocomplete="off" />
                                        </div>
                                        <div class="form-group text-left">
                                            <label class="label form_label">Pick Up Address</label>
                                            <Field type="text" name="address" class="form-control pac-target-input" placeholder="Pick Up Address" required autocomplete="off" />
                                        </div>
                                        <div className="form-group text-left d-flex">
                                            <div className="d-flex mr-3 form-check">
                                                <Field className="mt-2 mr-2 form-check-input" onClick={(e) => { change(e) }} type="radio" name="pay" value="cod" />
                                                <label>
                                                    Pay Driver ({total().toFixed(2)})
                                                </label>
                                            </div>
                                            <div className="d-flex">
                                                <Field className="mt-2 mr-2" onClick={(e) => { change(e) }} type="radio" name="pay" value="Online" />
                                                <label>
                                                    Pay full online ({total().toFixed(2)})
                                                </label>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <div class="form-group">
                                                <input type="submit" onClick={() => { submitPersonalInfo() }} id="distance_form" class="btn btn-outline-warning py-3 px-4" value="Confirm booking" />
                                            </div>
                                        </div>
                                    </Form>
                                </Formik>
                            </div>
                        </div>
                        <div className='col-12 col-md-6'>
                            <div className="row m-0 justify-content-center mt-2 mt-md-0">
                                <div class="form_wrap_personal mb-2">
                                    <div className="navbar-brand"><span>Trip Details</span></div>
                                    <div className="row m-0 font16 fontwt400 font_fam1 mb-1">
                                        <div className='col-3 text-center'>Itenary</div>
                                        <div className='col d-flex flex-wrap'>{pickUpArray[0]}<i class="icofont-long-arrow-right ml-1"></i>{dropArray[0]}<i class="icofont-long-arrow-right ml-1"></i>{pickUpArray[0]}</div>
                                    </div>
                                    <div className="row m-0 font16 fontwt400 font_fam1 mb-1">
                                        <div className='col-3 text-center'>Start Date</div>
                                        <div className='col d-flex flex-wrap'>{TripData.checkInDate}</div>
                                    </div>
                                    {TripData.picked === 'Round' && TripData.checkOutDate && (<div className="row m-0 font16 fontwt400 font_fam1 mb-1">
                                        <div className='col-3 text-center'>Drop Date</div>
                                        <div className='col d-flex flex-wrap'>{TripData.checkOutDate}</div>
                                    </div>)}
                                    <div className="row m-0 font16 fontwt400 font_fam1 mb-1">
                                        <div className='col-3 text-center'>Pick Up Time</div>
                                        <div className='col d-flex flex-wrap'>{TripData.time}</div>
                                    </div>
                                    <div className="row m-0 font16 fontwt400 font_fam1 mb-1">
                                        <div className='col-3 text-center'>Car</div>
                                        <div className='col d-flex flex-wrap'>{window.localStorage.getItem('vehicleName')}</div>
                                    </div>
                                    <div className="row m-0 font16 fontwt400 font_fam1 mb-1">
                                        <div className='col-3 text-center'>Total</div>
                                        <div className='col d-flex flex-wrap'>{window.localStorage.getItem('vehiclePrice')}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0 justify-content-center">
                                <div class="form_wrap_personal pb-5">
                                    <div className="navbar-brand"><span>Total Fair</span></div>
                                    <div className="row m-0 font16 fontwt400 font_fam1 mb-2">
                                        <div className='col-3 text-center'>Item</div>
                                        <div className='col-3 text-center'>Per Klm</div>
                                        <div className='col-3 text-center'>Klm</div>
                                        <div className='col-3 text-right'>Amount</div>
                                    </div>
                                    <div className="row m-0 font16 fontwt400 font_fam1 mb-1 borderBottom">
                                        <div className='col-3 text-center font_fam1'>{tripInfo(TripData.picked)}</div>
                                        <div className='col-3 text-center'>{window.localStorage.getItem('vehiclePerKlm')}</div>
                                        <div className='col-3 text-center'>{window.localStorage.getItem('vehicleTotalKlm')}</div>
                                        <div className='col-3 text-right font_fam2'>{window.localStorage.getItem('vehiclePrice')}</div>
                                    </div>
                                    <div className="row m-0 font16 fontwt400 font_fam1 mb-1">
                                        <div className='col-3 text-center'>SGST</div>
                                        <div className='col-3 text-center'>2.5%</div>
                                        <div className='col-3 text-center'></div>
                                        <div className='col-3 text-right font_fam2'>{percentage(Number(window.localStorage.getItem('vehiclePrice')), Number(2.5))}</div>
                                    </div>
                                    <div className="row m-0 font16 fontwt400 font_fam1 mb-1 borderBottom">
                                        <div className='col-3 text-center'>CGST</div>
                                        <div className='col-3 text-center'>2.5%</div>
                                        <div className='col-3 text-center'></div>
                                        <div className='col-3 text-right font_fam2'>{percentage(Number(window.localStorage.getItem('vehiclePrice')), Number(2.5))}</div>
                                    </div>
                                    <div className="row m-0 font16 fontwt400 font_fam1 mb-1 borderBottom">
                                        <div className='col-3 text-center'>Total</div>
                                        <div className='col-3 text-center'></div>
                                        <div className='col-3 text-center'></div>
                                        <div className='col-3 text-right font_fam2'>{total().toFixed(2)}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};
export default PersonalInformation;