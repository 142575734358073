import React, { useEffect, useState } from "react";
import axios from "axios";
import variable from "../../common/variable";

const SuadiVehicle = () => {
    const [option, setOption] = useState(undefined);
    const selectedSaudi = window.localStorage.getItem('selectedSaudi');
    const selectedSaudiDetails = JSON.parse(window.localStorage.getItem('suadiDetails'));
    const ConfigURl = variable.host;
    const arr = [];
    useEffect(() => {
        axios.get(`${ConfigURl}/saudiVehicle/${selectedSaudi}`)
        .then(res => {
            // for(let i=0;i<res.data.length;i++) {
            //     arr.push({value: res.data[i].id, label: res.data[i].vehicle_name})
            // }
            setOption(res.data);
        });
    },[])

    console.log('options', option);

    const onSubmit = (details) => {
window.localStorage.setItem('selectedDetails', JSON.stringify(details));
window.location.replace('saudiBillingDetails');
    }

    return(
        <div className="px-5 pt-5 backgroundSaudi">
            <div className='bg_whiteSaudi m-0 p-5'>
        <div className="row m-0">
            <div className="col p-0">
            <div className="m-0 font16 fontwt400 font_fam1 mb-1">
                <div className='text-center mb-3'>Destination</div>
                <div className='text-center flex-wrap'>{selectedSaudiDetails.destination.label}</div>
            </div>
            </div>
            <div className="col p-0">
            <div className="m-0 font16 fontwt400 font_fam1 mb-1">
                <div className='text-center mb-3'>Start date</div>
                <div className='text-center flex-wrap'>{selectedSaudiDetails.checkInDate}</div>
            </div>
            </div>
            <div className="col p-0">
            <div className="m-0 font16 fontwt400 font_fam1 mb-1">
                <div className='text-center mb-3'>Pick Up Time</div>
                <div className='text-center'>{selectedSaudiDetails.time}</div>
            </div>
            </div>
        </div>
      </div>
      <div className="pt-5 row m-0 flex-wrap pb-5">
                {option && option.map((item) => (
                    <div class="col-md-4 mb-3">
                        {console.log('item', item)}
                        <div class="col-md-12 card p-0">
                            <div className='row m-0'>
                                <img className='w-100' src={item.vehicle_image ? `https://www.aywacab.com/DirectImage/${item.vehicle_image}` : "https://stat.overdrive.in/wp-content/odgallery/2020/06/57263_2020_Mercedes_Benz_GLS.jpg"} alt="car" />
                            </div>
                            <div className='row justify-content-center vehicleMainsaudi'>
                                {item.vehicle_name}
                            </div>
                            <div className='row justify-content-center vehiclePax'>
                                ({item.pax} Pax)
                            </div>
                            <div>
                            <div className='m-0 text-center'>
                                    <div className='vehicle_header'>
                                        Price
                                    </div>
                                    <div className='vehicle_value'>
                                    ₹ {Number(item.vehicle_price).toFixed(2)}
                                    </div>
                                </div>
                            </div>
                            <div className="text-center">
                                <div class="form-group">
                                {/* <div role="presentation" className='cursor_pointer font_fam2' onClick={handleShow}> */}
                                {/* View Details */}
                                {/* </div> */}
                                   {/* <div className='cursor_pointer font_fam2'>View Details</div> */}
                                </div>
                            </div>
                            <div className="text-center my-3">
                                <div class="form-group">
                                    <button  type="button" onClick={() => {onSubmit(item)}} class="btn btn-outline-warning py-3 px-4">Book Cab</button>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
export default SuadiVehicle;