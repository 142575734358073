import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from 'formik';
import DatePicker from "react-datepicker";
import moment from "moment";
import axios from "axios";
import Select from 'react-select';
import Autocomplete from "react-google-autocomplete";
import ReactFlagsSelect from "react-flags-select";
import variable from "../../common/variable";
// import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';

const arr = [];
const arrVehicle = [];
const Airport = () => {
    const [toAddress, setToAddress] = useState('');
    const [fromAddress, setFromAddress] = useState('');
    const [checkInDate, setCheckInDate] = useState(null);
  const [checkOutDate, setCheckOutDate] = useState(null);
  const [selectedOption, setSelectedOption] = useState();
  const [selectedOptionVehicle, setSelectedOptionVehicle] = useState();
  const [option, setOption] = useState(undefined);
  const [optionVehicle, setOptionVehicle] = useState(undefined);
  const [formShow, setformShow] = useState('');
  const [selected, setSelected] = useState("");
  const onSelect = (code) => {
    // console.log('code', code);
    // if (code === 'US') {
    //   setSelected(2);
    // } if (code === 'AE') {
      setSelected(code);
    // } else {
    //   setSelected(1);
    // }
  }

  const SelectedCurrency = window.localStorage.getItem('countryId');
  const ConfigURl = variable.host;
  const ssl = window.location.hostname === 'localhost' ? '' : 's';

  console.log(SelectedCurrency);

  useEffect(()=>{
    axios.get(`${ConfigURl}/getcurrency`)
    .then(res => {
        for(let i=0;i<res.data.length;i++) {
            arr.push({value: res.data[i].currency_id, label: res.data[i].currency_name})
        }
        setOption(arr);
    });
    axios.get(`${ConfigURl}/getCountry`)
    .then(res => {
        for(let i=0;i<res.data.length;i++) {
            arrVehicle.push({value: res.data[i].country_id, label: res.data[i].country_name})
        }
        setOptionVehicle(arrVehicle);
    })
  }, []);
  console.log('res', arr, option);

  const handleCheckInDate = (date) => {
    setCheckInDate(date);
    setCheckOutDate(null);
  };

  const handleCheckOutDate = (date) => {
    setCheckOutDate(date);
  };

    const saveOutstaion = (data) => {
        axios.post(`${ConfigURl}/getDistance`, {
            pick_up: fromAddress,
            drop_off: toAddress
          })
          .then(function (response) {
            console.log('res', response.data);
            window.localStorage.setItem('kms', response.data);
            const dataToSend = {...data};
        dataToSend.checkInDate = moment(checkInDate).format('MM/DD/YYYY');
        dataToSend.checkOutDate = moment(checkOutDate).format('MM/DD/YYYY');
        var date1 = new Date(dataToSend.checkInDate);
        var date2 = new Date(dataToSend.checkOutDate);
        var Difference_In_Time = date2.getTime() - date1.getTime();
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        dataToSend.days = Difference_In_Days;
        dataToSend.currency = window.localStorage.getItem("countryId");
        dataToSend.country = selected;
        dataToSend.pickUp = fromAddress;
        dataToSend.drop = toAddress;
        console.log(dataToSend);
        window.localStorage.setItem('tripDetails', JSON.stringify(dataToSend));
        window.location.replace('Vehicle');
          })
          .catch(function (error) {
            console.log(error);
          });
    }

    const handleChange = (e) => {
        setSelectedOption(e);
    };

    const handleChangeVehicle = (e) => {
        setSelectedOptionVehicle(e);
    };

    const change = (e) => {
console.log('eee', e.target.value);
setformShow(e.target.value);
    }
    return (
        <Formik
            initialValues={{
                pickUp: '',
                drop: '',
            }}
            onSubmit={async (values) => {
                await saveOutstaion(values);
            }}
        >
            <Form className="px-3">
            {/* <div class="form-group text-left">
                    <label class="label form_label">Currency</label>
                    <Select
                    className="form-control pac-target-input"
                    name="currency"
        value={selectedOption}
        onChange={(e) => {handleChange(e)}}
        options={option}
      />
                </div> */}
                <div class="form-group text-left">
                    <label class="label form_label">Choose country to choose vehicle</label>
                    <ReactFlagsSelect
                    className="form-control pac-target-input"
        selected={selected}
        onSelect={onSelect}
        countries={["US", "AE", "IN", "OM"]}
        blacklistCountries={false}
      />
                    {/* <Select
                    className="form-control pac-target-input"
                    name="country"
        value={selectedOptionVehicle}
        onChange={(e) => {handleChangeVehicle(e)}}
        options={optionVehicle}
      /> */}
                </div>
                <div class="form-group text-left">
                    <label class="label form_label">Pick-Up location</label>
                    <Autocomplete
                        class="form-control pac-target-input"
                        placeholder="City, Airport, Station, etc"
                        apiKey="AIzaSyB_vZvR6ARKJ1gIQbwwMkHDkflOQKs8XCE"
                        onPlaceSelected={(place, geometry) => {
                            setFromAddress(place.formatted_address);
                        }}
                        options={{
                          types: [],
                          componentRestrictions: { country: selected },
                        }}
                    />
                </div>
                <div class="form-group text-left">
                    <label class="label form_label">Drop-Off location</label>
                    <Autocomplete
                        class="form-control pac-target-input"
                        placeholder="City, Airport, Station, etc"
                        apiKey="AIzaSyB_vZvR6ARKJ1gIQbwwMkHDkflOQKs8XCE"
                        onPlaceSelected={(place, geometry) => {
                            setToAddress(place.formatted_address);
                        }}
                        options={{
                          types: [],
                          componentRestrictions: { country: selected },
                        }}
                    />
                </div>
                <div className="row">
                <div className="form-group text-left col">
          <label className="label form_label">Check-in</label>
          <DatePicker
          className="form-control pac-target-input"
            selected={checkInDate}
            minDate={new Date()}
            onChange={handleCheckInDate}
          />
        </div>
                </div>
                <div class="form-group text-left">
                    <label class="label form_label">Pick Up Time</label>
                    <Field type="time" name="time" class="form-control pac-target-input" placeholder="Pick Up Time" required="" autocomplete="off" />
                </div>
                <div className="text-center">
               <div class="form-group">
               <input type="submit" id="distance_form" class="btn btn-outline-warning py-3 px-4" value="Search cab" />
               </div>
               </div>
            </Form>
        </Formik>
    )
}
export default Airport;