import React, { useState } from 'react'
import axios from 'axios';
import variable from "../../common/variable";

const Pnr = () => {
  const ConfigURl = variable.host;
const [pnr, setPnr] = useState('');
const [details, setDetails] = useState('');

  const onChange = (e) => {
setPnr(e.target.value);
  };

  console.log(details.length);

  const submitPnr = () => {
console.log(pnr);
axios.get(`${ConfigURl}/searchPnr/${pnr}`)
        .then(res => {
          console.log('res',res.data);
            setDetails(res.data)
        });
  }


    return(
        <>
       <div className="bg-form-billing">
        <div className="">
          <div className='row m-0'>
            <div className='col-4'>
            <div class="form-group mt-4">
    <input type="text" class="form-control" id="inputAddress" value={pnr} placeholder="Get Pnr Details" onChange={(e) => {onChange(e)}} />
    </div>
            </div>
            <div className='col-2 mt-3'>
            <div class="form-group">
              <input type="button" onClick={() => {submitPnr()}} id="distance_form" class="btn btn-outline-warning py-3 px-4" value="Search" />
            </div>
            </div>
          </div>
        </div>
        <center>
        <div className=''>
{details.length > 0 ? (
  <div>
    <div class="confirm mt-5 col-12 col-md-8">
            <div className='row font16 fontwt400 font_fam1 m-0 justify-content-center'>
                Booking Details
            </div>
        </div>
        {details[0].trip_type !== "Saudi Arabia" && (
          <><div class="col-12 col-md-8 border" style={{background: '#42429866'}}>
            <div className="row m-0 font16 fontwt400 mb-1 borderBottom">
                <div className='col-5 text-center'>PNR ID</div>
                <div className='col-7 d-flex flex-wrap'>{details[0].pnr_id}</div>
                </div>
                <div className="row m-0 font16 fontwt400 mb-1 borderBottom">
                <div className='col-5 text-center'>Name</div>
                <div className='col-7 d-flex flex-wrap'>{details[0].customer_name}</div>
                </div>
                <div className="row m-0 font16 fontwt400 mb-1 borderBottom">
                <div className='col-5 text-center'>Email</div>
                <div className='col-7 d-flex flex-wrap'>{details[0].customer_email}</div>
                </div>
                <div className="row m-0 font16 fontwt400 mb-1 borderBottom">
                <div className='col-5 text-center'>Phone Number</div>
                <div className='col-7 d-flex flex-wrap'>{details[0].customer_phone}</div>
                </div>
            <div className="row m-0 font16 fontwt400 mb-1 borderBottom">
                <div className='col-5 text-center'>Itenary</div>
                <div className='col-7 d-flex flex-wrap'>{details[0].pick_up}<i class="icofont-long-arrow-right ml-1 mt-1"></i>{details[0].drop_off}<i class="icofont-long-arrow-right ml-1 mt-1"></i>{details[0].pick_up}</div>
                </div>
                <div className="row m-0 font16 fontwt400 mb-1 borderBottom">
                <div className='col-5 text-center'>Start Date</div>
                <div className='col-7 d-flex flex-wrap'>{details[0].pick_up_date}</div>
                </div>
                {details[0].trip_type === 'Round' && details[0].drop_of_date && (<div className="row m-0 font16 fontwt400 mb-1 borderBottom">
                <div className='col-5 text-center'>Drop Date</div>
                <div className='col-7 d-flex flex-wrap'>{details[0].drop_of_date}</div>
                </div>)}
                <div className="row m-0 font16 fontwt400 mb-1 borderBottom">
                <div className='col-5 text-center'>Pick Up Time</div>
                <div className='col-7 d-flex flex-wrap'>{details[0].pick_up_time}</div>
                </div>
                <div className="row m-0 font16 fontwt400 mb-1 borderBottom">
                <div className='col-5 text-center'>Car</div>
                <div className='col-7 d-flex flex-wrap'>{details[0].vehicle_name}</div>
                </div>
                <div className="row m-0 font16 fontwt400 mb-1">
                <div className='col-5 text-center'>Total</div>
                <div className='col-7 d-flex flex-wrap'>{details[0].total_booking_price}</div>
                </div>
        </div></>
        )}
        {details[0].trip_type === "Saudi Arabia" && (
          <><div class="col-12 col-md-8 border" style={{background: '#42429866'}}>
            <div className="row m-0 font16 fontwt400 mb-1 borderBottom">
                <div className='col-5 text-center'>PNR ID</div>
                <div className='col-7 d-flex flex-wrap'>{details[0].pnr_id}</div>
                </div>
                <div className="row m-0 font16 fontwt400 mb-1 borderBottom">
                <div className='col-5 text-center'>Name</div>
                <div className='col-7 d-flex flex-wrap'>{details[0].customer_name}</div>
                </div>
                <div className="row m-0 font16 fontwt400 mb-1 borderBottom">
                <div className='col-5 text-center'>Email</div>
                <div className='col-7 d-flex flex-wrap'>{details[0].customer_email}</div>
                </div>
                <div className="row m-0 font16 fontwt400 mb-1 borderBottom">
                <div className='col-5 text-center'>Phone Number</div>
                <div className='col-7 d-flex flex-wrap'>{details[0].customer_phone}</div>
                </div>
            <div className="row m-0 font16 fontwt400 mb-1 borderBottom">
                <div className='col-5 text-center'>Itenary</div>
                <div className='col-7 d-flex flex-wrap'>{details[0].pick_up}</div>
                </div>
                <div className="row m-0 font16 fontwt400 mb-1 borderBottom">
                <div className='col-5 text-center'>Start Date</div>
                <div className='col-7 d-flex flex-wrap'>{details[0].pick_up_date}</div>
                </div>
                <div className="row m-0 font16 fontwt400 mb-1 borderBottom">
                <div className='col-5 text-center'>Pick Up Time</div>
                <div className='col-7 d-flex flex-wrap'>{details[0].pick_up_time}</div>
                </div>
                <div className="row m-0 font16 fontwt400 mb-1 borderBottom">
                <div className='col-5 text-center'>Car</div>
                <div className='col-7 d-flex flex-wrap'>{details[0].vehicle_name}</div>
                </div>
                <div className="row m-0 font16 fontwt400 mb-1">
                <div className='col-5 text-center'>Total</div>
                <div className='col-7 d-flex flex-wrap'>{details[0].total_booking_price}</div>
                </div>
        </div></>
        )}
    </div>
) : (<div>
<>
      <div class="confirm mt-5 col-12 col-md-8">
            <div className='row font16 fontwt400 font_fam1 m-0 justify-content-center'>
                Booking Details
            </div>
        </div>
        <div className="row m-0 font16 fontwt400 mb-1">
                <div className='col-12 text-center' style={{color: 'black'}}>No Record Found</div>
                </div>
      </>
  </div>)}
       </div>
        </center>
    </div>
</>
    )
}
export default Pnr;