import React from 'react';
import StartingForm from '../StartingForm';

const Main = () => {
    return (
        <>
            <div className="banner">
                <div className="container">
                    <div className="banner-text">
                        <div className="banner-heading">
                            <div className="navbar-brand" style={{ fontSize: 42 }}>Book <span style={{ fontSize: 42 }}>Aywa cabs</span> Now !!!</div>
                        </div>
                        {/* <div className="banner-sub-heading">
	Here goes the secondary heading on hero banner
	</div> */}
                        <a href='#aboutus' type="button" className="btn btn-warning text-dark btn-banner">Get started</a>
                    </div>
                </div>
            </div>
            <section id="aboutus">
                <StartingForm />
                {/* <div className="banner">
	<div className="container">
	<StartingForm />
	</div>
</div> */}
            </section>
            <section id="contact" class="contact">
                <div class="container">

                    <div class="section-title d-flex justify-content-center">
                        <div className="navbar-brand" style={{ fontSize: 42, color: '#5f5950' }}><span style={{ fontSize: 42 }}>Contact</span>Us</div>
                    </div>
                    <div class="container mt-5">
                        <div class="info-wrap">
                            <div class="row">
                                <div class="col-lg-3 col-md-6 info">
                                    <i class="icofont-google-map"></i>
                                    <h4>Location:</h4>
                                    <p>#10,Ground Floor,<br /> Station Road Hubli, 580025 Karnataka INDIA</p>
                                </div>

                                <div class="col-lg-3 col-md-6 info mt-4 mt-lg-0">
                                    <i class="icofont-clock-time icofont-rotate-90"></i>
                                    <h4>Open Hours:</h4>
                                    <p>Monday-Saturday:<br />12:00 AM - 11:59 PM</p>
                                </div>

                                <div class="col-lg-3 col-md-6 info mt-4 mt-lg-0">
                                    <i class="icofont-envelope"></i>
                                    <h4>Email:</h4>
                                    <p>gm@rayntourism.com<br /></p>
                                </div>

                                <div class="col-lg-3 col-md-6 info mt-4 mt-lg-0">
                                    <i class="icofont-phone"></i>
                                    <h4>Call:</h4>
                                    <p>+917760412840<br />+918886959501</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}
export default Main;