import React from "react";
import Header from "../../image/Header.jpg";
import Footer from "../../image/Footer.jpg";

const Email = () => {
return(
    <div >
        <img width={'100%'} src={Header} alt="" />
        1
        <img width={'100%'} src={Footer} alt="" />
    </div>
)
};
export default Email;