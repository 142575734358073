import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from 'formik';
import DatePicker from "react-datepicker";
import moment from "moment";
import axios from "axios";
import Select from 'react-select';
import Autocomplete from "react-google-autocomplete";
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import variable from "../../common/variable";

const arr = [];
const arrVehicle = [];
const Local = () => {
  const [toAddress, setToAddress] = useState('');
  const [fromAddress, setFromAddress] = useState('');
    const [checkInDate, setCheckInDate] = useState(null);
    const [checkOutDate, setCheckOutDate] = useState(null);
    const [selectedOption, setSelectedOption] = useState();
    const [selectedOptionVehicle, setSelectedOptionVehicle] = useState();
    const [option, setOption] = useState(undefined);
    const [optionVehicle, setOptionVehicle] = useState(undefined);
    const ConfigURl = variable.host;
    const ssl = window.location.hostname === 'localhost' ? '' : 's';
  
    useEffect(()=>{
      axios.get(`${ConfigURl}/getcurrency`)
      .then(res => {
          for(let i=0;i<res.data.length;i++) {
              arr.push({value: res.data[i].currency_id, label: res.data[i].currency_name})
              arrVehicle.push({value: res.data[i].currency_id, label: res.data[i].currency_country})
          }
          setOption(arr);
          setOptionVehicle(arrVehicle);
      })
    }, []);
  
    const handleCheckInDate = (date) => {
      setCheckInDate(date);
      setCheckOutDate(null);
    };
  
    const handleCheckOutDate = (date) => {
      setCheckOutDate(date);
    };
  
      const saveOutstaion = (data) => {
          console.log(data);
          axios.post(`${ConfigURl}/getDistance`, {
            pick_up: fromAddress,
            drop_off: toAddress
          })
          .then(function (response) {
            console.log('res', response.data);
            window.localStorage.setItem('kms', response.data);
            const dataToSend = {...data};
            dataToSend.checkInDate = moment(checkInDate).format('MM/DD/YYYY');
            // dataToSend.checkOutDate = moment(checkOutDate).format('MM/DD/YYYY');
            // var date1 = new Date(dataToSend.checkInDate);
            // var date2 = new Date(dataToSend.checkOutDate);
            // var Difference_In_Time = date2.getTime() - date1.getTime();
            // var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
            dataToSend.picked = data.picked;
            dataToSend.country = "IN";
            dataToSend.currency = window.localStorage.getItem("countryId");
            dataToSend.pickUp = fromAddress;
            dataToSend.drop = toAddress;
            console.log(dataToSend);
            console.log('data', dataToSend ,data);
            window.localStorage.setItem('tripDetails', JSON.stringify(dataToSend));
            window.location.replace('LocalVehicle');
          })
      }
  
      const handleChange = (e) => {
          setSelectedOption(e);
      };
  
      const handleChangeVehicle = (e) => {
          setSelectedOptionVehicle(e);
      };
      return (
          <Formik
              initialValues={{
                  pickUp: '',
                  drop: '',
              }}
              onSubmit={async (values) => {
                  await saveOutstaion(values);
              }}
          >
              <Form className="px-3">
              <div class="form-group text-left">
                    <label class="label form_label">Pick-Up location</label>
                    <Autocomplete
                        class="form-control pac-target-input"
                        placeholder="City, Airport, Station, etc"
                        apiKey="AIzaSyB_vZvR6ARKJ1gIQbwwMkHDkflOQKs8XCE"
                        onPlaceSelected={(place, geometry) => {
                            setFromAddress(place.formatted_address);
                        }}
                        options={{
                          types: ['(cities)'],
                          componentRestrictions: { country: 'IN' },
                        }}
                    />
                </div>
                <div class="form-group text-left">
                    <label class="label form_label">Drop-Off location</label>
                    <Autocomplete
                        class="form-control pac-target-input"
                        placeholder="City, Airport, Station, etc"
                        apiKey="AIzaSyB_vZvR6ARKJ1gIQbwwMkHDkflOQKs8XCE"
                        onPlaceSelected={(place, geometry) => {
                            setToAddress(place.formatted_address);
                        }}
                        options={{
                          types: ['(cities)'],
                          componentRestrictions: { country: 'IN' },
                        }}
                    />
                </div>
                  <div className="row">
                  <div className="form-group text-left col">
            <label className="label form_label">Check-in</label>
            <DatePicker
            className="form-control pac-target-input"
              selected={checkInDate}
              minDate={new Date()}
              onChange={handleCheckInDate}
            />
          </div>
          {/* <div className="form-group text-left col">
            <label className="label form_label">Check-out</label>
            <DatePicker
            name="frondate"
              className="form-control pac-target-input"
              selected={checkOutDate}
              minDate={checkInDate}
              onChange={handleCheckOutDate}
            />
          </div> */}
                  </div>
                  <div className="form-group text-left">
                  <div className="col p-0">
                    <label className="label form_label text-left">Trip type</label>
                    </div>
                    <div className="d-flex">
                    <div className="d-flex mr-3 form-check">
              <Field className="mt-2 mr-2 form-check-input" type="radio" name="picked" value="4" />
              <label>
                4 hours
              </label>
              </div>
              <div className="d-flex mr-3 form-check">
              <Field className="mt-2 mr-2 form-check-input" type="radio" name="picked" value="8" />
              <label>
                8 hours
              </label>
              </div>
              <div className="d-flex mr-3 form-check">
              <Field className="mt-2 mr-2 form-check-input" type="radio" name="picked" value="12" />
              <label>
                12 hours
              </label>
              </div>
                    </div>
              </div>
                  <div class="form-group text-left">
                      <label class="label form_label">Pick Up Time</label>
                      <Field type="time" name="time" class="form-control pac-target-input" placeholder="Pick Up Time" required="" autocomplete="off" />
                  </div>
                  <div className="text-center">
                 <div class="form-group">
                 <input type="submit" class="btn btn-outline-warning py-3 px-4" value="Search cab" />
                 </div>
                 </div>
              </Form>
          </Formik>
      )
  }
export default Local;