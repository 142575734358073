import './App.css';
import Header from './component/Header';
import Main from './component/Main';

const App = () => {
  return (
      <header>
        <Header />
        <Main />
      </header>
  );
}

export default App;
