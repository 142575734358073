import React, { useEffect, useState } from 'react';
import Header from '../Header';
import {Modal, Button} from 'react-bootstrap';
import axios from 'axios';
import etios from '../../image/etios.png'
import variable from '../../common/variable';

const Vehicle = () => {
    const TripData = JSON.parse(window.localStorage.getItem('tripDetails'));
    console.log('hello', TripData.days);
    const pickUpArray = TripData.pickUp.split(",");
    const dropArray = TripData.drop.split(",");
    const kms = window.localStorage.getItem('kms');
    const TripType = TripData.picked;
    const days = TripData.days;
    const country = TripData.country;
    const checkInDate = TripData.checkInDate;
    const checkOutDate = TripData.checkOutDate;
    const time = TripData.time;
    const [vehicle, setVehicle] = useState();
    const [vehiclePrice, setVehiclePrice] = useState();
    const ConfigURl = variable.host;
    const ssl = window.location.hostname === 'localhost' ? '' : 's';
    useEffect(() => {
        axios.get(`${ConfigURl}/getVehicle/${country}`)
            .then(res => {
                console.log(res);
                setVehicle(res.data);
                setVehiclePrice(res.data);
            })
    }, []);

    const Str = kms.match(/(\d+)/);
        const kilometer = Str[0];
    const finalTotal = (price) => {
        console.log('kms', kilometer * Number(price));
        let finalAmount = 0;

//        144  2  288  275 * 550 
if(TripType === 'Round') {
    if (Number(kilometer) * 2 > 275 * days) {
        finalAmount = Number(price) * (2 * Number(kilometer));
    } else {
        finalAmount = Number(price) * 275 * days;
    }
    return finalAmount;
} else {
    if (Number(kilometer) * 2 > 275 * days) {
        finalAmount = Number(price) * (2 * Number(kilometer));
    } else {
        finalAmount = Number(price) * 275 * days;
    }
    return finalAmount;
}; 
}

    const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const selectCar = (data, vehicleName, perRate, vehicle_id) => {
    console.log('select', data);
    window.localStorage.setItem('vehiclePrice', data);
    window.localStorage.setItem('vehiclePerKlm', perRate)
    window.localStorage.setItem('vehicle_Id', vehicle_id);
    if(TripType === 'Round') {
        if (Number(kilometer) * 2 > 275 * days) {
            window.localStorage.setItem('vehicleTotalKlm', Number(kilometer) * 2);
        } else {
            window.localStorage.setItem('vehicleTotalKlm', 275);
        }
    } else {
        if (Number(kilometer) * 2 > 275 * days) {
            window.localStorage.setItem('vehicleTotalKlm', Number(kilometer) * 2);
        } else {
            window.localStorage.setItem('vehicleTotalKlm', 275);
        }
    }
    window.localStorage.setItem('vehicleName', vehicleName);
    window.location.replace('PersonalInformation');
  };

    console.log(vehicle);
    return (
        <div className='px-5 bgAll pt-5'>
            <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Trip Details</Modal.Title>
        </Modal.Header>
        <Modal.Body></Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
      <div className='bg_white m-0 p-5'>
        <div className="row m-0">
            <div className="col p-0">
            <div className="m-0 font16 fontwt400 font_fam1 mb-1">
                <div className='text-center mb-3'>Itenary</div>
                <div className='d-flex  flex-wrap'>{pickUpArray[0]}<i class="icofont-long-arrow-right ml-1"></i>{dropArray[0]}<i class="icofont-long-arrow-right ml-1"></i>{pickUpArray[0]}</div>
            </div>
            </div>
            <div className="col p-0">
            <div className="m-0 font16 fontwt400 font_fam1 mb-1">
                <div className='text-center mb-3'>Start date</div>
                <div className='text-center flex-wrap'>{checkInDate}</div>
            </div>
            </div>
            <div className="col p-0">
            <div className="m-0 font16 fontwt400 font_fam1 mb-1">
                <div className='text-center mb-3'>End date</div>
                <div className='text-center'>{checkOutDate}</div>
            </div>
            </div>
            <div className="col p-0">
            <div className="m-0 font16 fontwt400 font_fam1 mb-1">
                <div className='text-center mb-3'>Distance</div>
                <div className='text-center'>{kms}</div>
            </div>
            </div>
            <div className="col p-0">
            <div className="m-0 font16 fontwt400 font_fam1 mb-1">
                <div className='text-center mb-3'>Pick Up Time</div>
                <div className='text-center'>{time}</div>
            </div>
            </div>
        </div>
      </div>
            <div className="pt-5 row m-0 flex-wrap pb-5">
                {vehicle && vehicle.map((item) => (
                    <div class="col-md-4 mb-3">
                        {console.log(item)}
                        <div class="col-md-12 card p-0">
                            <div className='row m-0'>
                                <img className='w-100' src={item.vehicle_image ? `https://www.aywacab.com/DirectImage/${item.vehicle_image}` : "https://stat.overdrive.in/wp-content/odgallery/2020/06/57263_2020_Mercedes_Benz_GLS.jpg"} alt="car" />
                            </div>
                            <div className='row justify-content-center vehicleMain'>
                                {item.vehicle_name}
                            </div>
                            <div className='row m-0'>
                                <div className='col-6 text-center'>
                                    <div className='vehicle_header'>
                                        Price
                                    </div>
                                    <div className='vehicle_value'>
                                    ₹ {item.vehicle_price.toFixed(2)}
                                    </div>
                                </div>
                                <div className='col-6 text-center'>
                                    <div className='vehicle_header'>
                                        Total
                                    </div>
                                    <div className='vehicle_value'>
                                    ₹ {finalTotal(item.vehicle_price).toFixed(2)}
                                    </div>
                                </div>
                            </div>
                            <div className="text-center">
                                <div class="form-group">
                                <div role="presentation" className='cursor_pointer font_fam2' onClick={handleShow}>
                                {/* View Details */}
                                </div>
                                   {/* <div className='cursor_pointer font_fam2'>View Details</div> */}
                                </div>
                            </div>
                            <div className="text-center my-3">
                                <div class="form-group">
                                    <button onClick={() => { selectCar(finalTotal(item.vehicle_price).toFixed(2), item.vehicle_name, item.vehicle_price, item.vehicle_id) }} type="submit" class="btn btn-outline-warning py-3 px-4">Book Cab</button>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
};
export default Vehicle; 